import jsMD5 from "js-md5"
import axios from "axios"

/**
 * This method return a list of unique strings from any type of variable.
 * This method will be used to populate autocomplete search fields.
 * @param {*} data that will be input for generation of unique strings.
 * @param {*} excludedKeys object keys to be excluded while constructing unique strings.
 */
export const getListOfStrings = (data, excludedKeys) =>  {
  const results = new Array()
  if(data !== undefined) {
    if(Array.isArray(data)) {
      for(const item of data) {
        results.push(...getListOfStrings(item, excludedKeys))
      }
    } else if (typeof data === "object"){
      if(data) {
        for(const entry of Object.entries(data)) {
          if (!excludedKeys || !excludedKeys.includes(entry[0])) {
            results.push(...getListOfStrings(entry[1], excludedKeys))
          }
        }
      }
    } else {
      results.push(data.toString())
    }
  }
  return Array.from(new Set(results))
}

/**
 * This method replaces all the placeholders in a string
 * @param data contains the value in which placeholders to be replaced
 * @param args contains list of values for placeholders as an arguments
 */
export const format = (data, ...args) => {
  return data.replace(/((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g, (m, str, index) => {
    if (str) {
      return str.replace(/(?:{{)|(?:}})/g, x => x[0])
    } else {
      if (index < args.length) {
        return args[index]
      }
    }
  })
}

/**
 * This method returns elements in first array which are not present in second array of objects
 * @param {*} firstArray array of objects from which needs to be filtered.
 * @param {*} secondArray array of objects that shouldn't be returned.
 * @param {*} key key based on which difference will be found.
 */
export const getObjectsOnlyInFirstArray = (firstArray, secondArray, key) => {
  const valueOfKeysInSecondArray = secondArray.map(item => item[key])
  return firstArray.filter(item => {
    return !valueOfKeysInSecondArray.includes(item[key])
  })
}

/**
 * This method negates boolean.
 * @param {*} value value to be negated.
 */
export const negateBoolean = value => {
  if (value === undefined || value === null) {
    return value
  } else {
    return !value
  }
}

/**
 * This method will return two character initials for the passed string.
 * @param {*} value to converted to initials.
 */
export const getInitials = value => {
  if(value) {
    const nameParts = value.split(" ")
    let initials
    if(nameParts.length === 1){
      initials = nameParts[0].charAt(0, 1).toUpperCase()
    } else if (nameParts.length > 1) {
      initials = nameParts[0].charAt(0).toUpperCase() +
      nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    }
    return initials
  }
}

/**
 * This method will download a file from a url.
 * @param {*} url url of file to be downloaded.
 * @param {*} downloadName name of file after download.
 */
export const downloadFile = async (url, md5, downloadName, download = true) => {
  const result = await axios.get(url, {
    responseType: "arraybuffer"
  })
  if (!md5 || jsMD5(result.data) === md5) {
    const url = window.URL.createObjectURL(new Blob([result.data]))
    if (download) {
      const link  = document.createElement("a")
      link.href   = url
      link.target = "_blank"
      if (downloadName) {
        link.download = downloadName
      }
      link.click()
    } else {
      return url
    }
  }
}

/**
 * This method converts camel case to snake case
 * @param {*} value value to be converted.
 */
export const camelToSnake = value => {
  var result = value.replace( /([A-Z])/g, " $1" )
  return result.split(" ").join("_").toLowerCase()
}

/**
 * This method will convert string to title case.
 * @param {*} value to string to be converted.
 */
export const toTitleCase = value  => {
  return value.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    }
  )
}

/**
 * This method will add days to date and return new date.
 * @param {*} date is the date to which days to be added.
 * @param {*} days is the days that has to be added to date.
 */
export const addDays = (date, days) => {
  return date.setDate(date.getDate() + days)
}

/**
 * This method will generate md5 checksum for a file.
 * @param {*} file contains content/details of file.
 */
export const generateMD5ForFile = file  => {
  const reader    = new FileReader()
  const md5Result = new Promise(resolve => {
    reader.onload  = (function(event) {
      resolve(jsMD5(event.target.result))
    })
    reader.onerror = function(event) {
      resolve(event)
    }
    reader.readAsArrayBuffer(file)
  })

  return md5Result
}

export const compareArray = (array1, array2) => {
  if (array1 || array2) {
    if (array1?.length === array2?.length) {
      for (const item of array1) {
        if (!array2.includes(item)) {
          return false
        }
      }
    } else {
      return false
    }
  }
  return true
}

export const getItemsInOriginalOrder = (original, current) => {
  const result = []
  for (const item of original) {
    if (current.includes(item)) {
      result.push(item)
    }
  }
  for (const item of current) {
    if (!original.includes(item)) {
      result.push(item)
    }
  }
  return result
}