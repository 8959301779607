/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}configurations`

export default {
  /**
   * This action will load configurations.
   * @param {*} context is the store.
   */
  async loadConfigurations(context) {
    context.commit("setLoadingConfigurations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getConfigurationsResponse = await httpClient.get()

    if (getConfigurationsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setConfigurations", getConfigurationsResponse.data)
    } else {
      // something went wrong while getting configurations
    }

    context.commit("setLoadingConfigurations", false)
  },

  /**
   * This action updates configurations.
   * @param {*} context is the store.
   * @param {*} configuration contains details of specific configuration.
   */
  async updateConfiguration(context, configuration) {
    const id = configuration.id
    delete configuration.id
    const propertiesToBeUpdated = Object.keys(configuration)
    for (const property of propertiesToBeUpdated) {
      if (configuration[property] === undefined) {
        configuration[property] = null
      }
    }
    context.commit("setUpdatingConfiguration", propertiesToBeUpdated)
    context.commit("resetConfigurationUpdated", propertiesToBeUpdated)
    context.commit("resetConfigurationUpdateError", propertiesToBeUpdated)

    const httpClient         = GET_HTTP_CLIENT({
      baseURL
    }, context)
    const updateUserResponse = await httpClient.put(`/${id}`, configuration)

    if (updateUserResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateConfigurations", [{
        id,
        ...configuration
      }])
      context.commit("setConfigurationUpdated", propertiesToBeUpdated)
    } else if (updateUserResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setConfigurationUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateUserResponse.data
      })
    } else {
      // something went wrong while updating an user
    }

    context.commit("resetUpdatingConfiguration", propertiesToBeUpdated)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingConfigurations", undefined)
    context.commit("setConfigurations", new Array())
  }
}