/**
 * @file It contains vuex reports module getter methods to access store variables.
 */
export default {
  isLoadingReports          : state => state.loadingReports,
  reports                   : state => state.reports,
  isReportAdded             : state => state.reportAdded,
  isAddingReport            : state => state.addingReport,
  isUpdatingReportFormValues: state => state.updatingReportFormValues,
  isReportFormValuesUpdated : state => state.reportFormValuesUpdated,
  isReportSent              : state => state.reportSent,
  isSendingReport           : state => state.sendingReport,
  reportFormValues          : state => state.reportFormValues
}