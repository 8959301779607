import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}form-instances`

export default {
  /**
   * This action will load form instances.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load formInstances.
   */
  async loadFormInstances(context, payload) {
    context.commit("setLoadingFormInstances", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getFormInstancesResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getFormInstancesResult.status === HTTP_STATUS_CODE.OK) {
      context.commit("setFormInstances", getFormInstancesResult.data)
    }

    context.commit("setLoadingFormInstances", false)
  },

  /**
   * This action will add a new formInstance.
   * @param {*} context is the store
   * @param {*} payload contains formInstance details
   */
  async addFormInstance(context, payload) {
    context.commit("setAddingFormInstance", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postFormInstanceResponse = await httpClient.post(undefined, payload)

    if (postFormInstanceResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateFormInstances", [postFormInstanceResponse.data])
    }

    context.commit("setAddingFormInstance", false)
  },

  /**
   * This action updates a formInstance.
   * @param {*} context is the store.
   * @param {*} formInstance id and summary of an formInstance.
   */
  async updateFormInstance(context, payload) {
    context.commit("setUpdatingFormInstance", true)
    context.commit("setFormInstanceUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateFormInstanceResponse = await httpClient.put(`/${payload.id}`, payload.data)

    if (updateFormInstanceResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setFormInstanceUpdated", true)
    }

    context.commit("setUpdatingFormInstance", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingFormInstances", undefined)
    context.commit("setAddingFormInstance", undefined)
    context.commit("setFormInstances", new Array())
    context.commit("setUpdatingFormInstance", undefined)
    context.commit("setFormInstanceUpdated", undefined)
  }
}