/**
 * @file It contains vuex reports module mutations to mutate the state variables.
 */
export default {
  setLoadingReports: (state, reports) => {
    state.loadingReports = reports
  },
  setReports: (state, reports) => {
    state.reports = reports
  },
  updateReports: (state, reports) => {
    for (const report of reports) {
      const index = state.reports.findIndex(stateReport => stateReport?.id === report.id)
      if (index >= 0) {
        state.reports.splice(index, 1, { ...state.reports[index], ...report })
      } else {
        state.reports.push(report)
      }
    }
  },
  setAddingReport: (state, addingReport) => {
    state.addingReport = addingReport
  },
  setReportAdded: (state, reportAdded) => {
    state.reportAdded = reportAdded
  },
  setUpdatingReportFormValues: (state, updatingReportFormValues) => {
    state.updatingReportFormValues = updatingReportFormValues
  },
  setReportFormValuesUpdated: (state, reportFormValuesUpdated) => {
    state.reportFormValuesUpdated = reportFormValuesUpdated
  },
  setLoadingReportFormValues: (state, reportFormValues) => {
    state.loadingReportFormValues = reportFormValues
  },
  setReportFormValues: (state, { reportId, formId, reportFormValues }) => {
    state.reportFormValues = {
      [reportId]: {
        [formId]: reportFormValues.map(reportFormValue => ({
          fieldId: reportFormValue.fieldId,
          value  : reportFormValue.value
        }))
      }
    }
  },
  updateReportFormValues: (state, reportFormValues) => {
    for (const reportFormValue of reportFormValues) {
      const index = state.reportFormValues.findIndex(stateReportIntakeValue =>
        stateReportIntakeValue?.id === reportFormValue.id
      )
      if (index >= 0) {
        state.reportFormValues.splice(index, 1, { ...state.reportFormValues[index], ...reportFormValue })
      } else {
        state.reportFormValues.push(reportFormValue)
      }
    }
  },
  setSendingReport: (state, sendingReport) => {
    state.sendingReport = sendingReport
  },
  setReportSent: (state, reportSent) => {
    state.reportSent = reportSent
  }
}