/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, MESSAGE_STATUS } from "@/constants"
import store from "@/plugins/vuex"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}reports`

export default {
  /**
   * This action will load a particular report.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadReports(context, payload) {
    context.commit("setLoadingReports", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getReportsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getReportsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateReports", getReportsResponse.data)
      } else {
        context.commit("setReports", getReportsResponse.data)
      }
    } else {
      // something went wrong while getting reports
    }

    context.commit("setLoadingReports", false)
  },

  /**
   * This action will add a report.
   * @param {*} context is the store.
   */
  async addReport(context, payload) {
    context.commit("setAddingReport", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL,
      auth: {
        username: "public",
        password: context.getters["channels/organisationCode"]
      }
    }, context)

    const postReportResponse = await httpClient.post(undefined, payload)

    if (postReportResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateReports", [postReportResponse.data])
      context.commit("setReportAdded", true)
    } else {
      // something went wrong while adding a report
    }

    context.commit("setAddingReport", false)
  },

  /**
   * This action will load a particular report form values.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadReportFormValues(context, payload) {
    context.commit("setLoadingReportFormValues", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getReportFormValuesResponse = await httpClient.get(`/${payload.reportId}/forms/${payload.formId}/values`)

    if (getReportFormValuesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setReportFormValues", {
        reportId        : payload.reportId,
        formId          : payload.formId,
        reportFormValues: getReportFormValuesResponse.data
      })
    } else {
      // something went wrong while getting report form values
    }

    context.commit("setLoadingReportFormValues", false)
  },

  /** This action updates form values of a report.
   * @param {*} context is the store.
   * @param {*} payload contains form value details.
   */
  async updateReportFormValues(context, payload) {
    context.commit("setUpdatingReportFormValues", true)
    context.commit("setReportFormValuesUpdated", false)

    const report = context.rootGetters["auth/report"]
    const id     = report.id

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateReportFormValuesResponse = await httpClient.put(`/${id}/forms/${payload.formId}/values`, payload.data)

    if (updateReportFormValuesResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setReportFormValues", {
        reportId        : payload.reportId,
        formId          : payload.formId,
        reportFormValues: payload.data
      })
      context.commit("setReportFormValuesUpdated", true)
    } else {
      // something went wrong while updating report intake values
    }

    context.commit("setUpdatingReportFormValues", false)
  },

  async sendReport(context, payload) {
    context.commit("setReportSent", false)
    context.commit("setSendingReport", true)

    const report     = context.rootGetters["auth/report"]
    const baseURL    = `${process.env.VUE_APP_THEMIS_API_BASE_URL}message-items`
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const addMessageItemResponse = await httpClient.post(undefined, payload)

    if (addMessageItemResponse.status === HTTP_STATUS_CODE.OK) {
      store.commit("messageItems/updateMessageItems", [addMessageItemResponse.data])
      const baseURL               = `${process.env.VUE_APP_THEMIS_API_BASE_URL}messages`
      const httpClient            = GET_HTTP_CLIENT({
        baseURL
      }, context)
      const messageUpdateData     = { status: MESSAGE_STATUS.NEW }
      const messageUpdateResponse = await httpClient.put(`/${payload.messageId}`, messageUpdateData)
      if (messageUpdateResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        store.commit("messages/updateMessages", [{
          id: payload.messageId,
          ...messageUpdateData
        }])
        if (report.status === MESSAGE_STATUS.DRAFT) {
          const baseURL              = `${process.env.VUE_APP_THEMIS_API_BASE_URL}reports`
          const httpClient           = GET_HTTP_CLIENT({
            baseURL
          }, context)
          const reportUpdateResponse = await httpClient.put(`/${report.id}`, { status: MESSAGE_STATUS.NEW })
          if (reportUpdateResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
            report.status = MESSAGE_STATUS.NEW
            store.commit("auth/setReport", report)
            context.commit("setReportSent", true)
          } else {
          // something went wrong while updating report
          }
        } else {
          context.commit("setReportSent", true)
        }
      } else {
        // something went wrong while updating message
      }
    } else {
      // something went wrong while adding message item
    }
    context.commit("setSendingReport", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setReports", new Array())
    context.commit("setLoadingReports", undefined)
    context.commit("setAddingReport", false)
    context.commit("setReportAdded", false)
    context.commit("setReportFormValuesUpdated", false)
    context.commit("setUpdatingReportFormValues", false)
    context.commit("setSendingReport", undefined)
    context.commit("setReportSent", undefined)
  }
}