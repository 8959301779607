/**
 * @file This file contains validation setup using vee validate
 */
import Vue from "vue"
import { extend, ValidationProvider, ValidationObserver } from "vee-validate"
import { required, email, max, size } from "vee-validate/dist/rules"
import i18n from "./i18n"

extend("required", {
  ...required,
  message: field => {
    if(field.startsWith("formInstanceField_")) {
      return i18n.t("145")
    }
    return i18n.t("60", { field: i18n.t(field) })
  }
})
extend("email", {
  ...email,
  message: field => {
    return i18n.t("59", { field: i18n.t(field) })
  }
})
extend("max", {
  ...max,
  message: (field, ...args) => {
    return i18n.t("61", { field: i18n.t(field), limit: args[0].length })
  }
})
extend("duplicate", {
  validate: (value, args) => {
    return args[0] === "false"
  },
  message: field => {
    return i18n.t("58", { field: i18n.t(field) })
  }
})

extend("size", {
  ...size,
  message: () => {
    return i18n.t("74")
  }
})

extend("custom", {
  validate: (value, args) => {
    if (args[0] === "false") {
      return true
    }
  },
  message: field => {
    return i18n.t("59", { field: i18n.t(field) })
  }
})

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)
