/**
 * @file It contains constants that are used in this application.
 */
import { CookieStorage } from "amazon-cognito-identity-js"

export const NEW_PASSWORD_REQUIRED = "New Password Required"

export const COOKIE_STORAGE_DATA = {
  expires : null,
  domain  : window.location.host.split(":")[0],
  secure  : true,
  sameSite: "lax",
  path    : "/"
}

export const COGNITO_USER_POOL_DATA = (UserPoolId, ClientId) => ({
  UserPoolId,
  ClientId,
  Storage: new CookieStorage(COOKIE_STORAGE_DATA)
})

export const HTTP_STATUS_CODE = {
  OK                   : 200,
  OK_WITH_NO_CONTENT   : 204,
  UNAUTHORIZED         : 401,
  FORBIDDEN            : 403,
  NOT_FOUND            : 404,
  METHOD_NOT_ALLOWED   : 405,
  BAD_REQUEST          : 400,
  CONFLICT             : 409,
  INTERNAL_SERVER_ERROR: 500
}

export const WIDGET = {
  AUTOCOMPLETE: "autocomplete"
}

export const FORM_INSTANCE_FIELD_TYPES = {
  OPTION_LIST: "option list"
}

export const ERROR = {
  DUPLICATE: "duplicate"
}

export const USER_ATTRIBUTE = {
  EMAIL: "email"
}

export const USER_ATTRIBUTE_UPDATE = {
  SUCCESS: "SUCCESS"
}
export const USER_ATTRIBUTE_VERIFICATION = {
  SUCCESS: "SUCCESS"

}

export const COGNITO_AUTHENTICATION_MESSAGE = {
  EXCEEDED_ATTEMPTS    : "Password attempts exceeded",
  NEW_PASSWORD_REQUIRED: "New Password Required",
  INVALID_CREDENTIALS  : "Incorrect username or password."
}

export const MESSAGE_STATUS = {
  DRAFT: "draft",
  NEW  : "new",
  SEEN : "seen"
}

export const  MESSAGE_ITEM_TYPE = {
  TEXT         : "text",
  PDF          : "application/pdf",
  RTF          : "application/rtf",
  DOC          : "application/msword",
  XLS          : "application/vnd.ms-excel",
  PPT          : "application/vnd.ms-powerpoint",
  DOCX         : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLSX         : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPTX         : "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  GIF          : "image/gif",
  PNG          : "image/png",
  JPG          : "image/jpeg",
  JPEG         : "image/jpeg",
  MP3          : "audio/mpeg",
  MP4          : "video/mp4",
  XWAV         : "audio/x-wav",
  WAV          : "audio/wav",
  WMV          : "audio/x-ms-wmv",
  MPEG         : "video/mpeg",
  MPG          : "video/mpeg",
  MPE          : "video/mpeg",
  MOV          : "video/quicktime",
  MSG          : "application/vnd.ms-outlook",
  MSG_EXTENSION: ".msg",
  AVI          : "video/x-msvideo",
  DOT          : "application/msword",
  XML          : "text/xml",
  DOTX         : "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  CSV          : "text/csv",
  HTML         : "text/html",
  HTM          : "text/html",
  TPL          : "text/html",
  XLW          : "application/vnd.ms-excel",
  XLT          : "application/vnd.ms-excel",
  XLTX         : "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  PPS          : "application/vnd.ms-powerpoint",
  POT          : "application/vnd.ms-powerpoint",
  POTX         : "application/vnd.openxmlformats-officedocument.presentationml.template",
  BMP          : "image/bmp",
  DXF          : "application/dxf",
  TIF          : "image/tif",
  TIFF         : "image/tiff",
  ODT          : "application/vnd.oasis.opendocument.text",
  OTH          : "application/vnd.oasis.opendocument.text-web",
  ODM          : "application/vnd.oasis.opendocument.text-master",
  ODS          : "application/vnd.oasis.opendocument.spreadsheet",
  OTS          : "application/vnd.oasis.opendocument.spreadsheet-template",
  ODP          : "application/vnd.oasis.opendocument.presentation",
  ODG          : "application/vnd.oasis.opendocument.graphics",
  OTP          : "application/vnd.oasis.opendocument.presentation-template",
  RTF_TEXT     : "text/rtf",
  WMV_VIDEO    : "video/x-ms-wmv",
  AVI_VIDEO    : "video/avi",
  X_DXF        : "application/x-dxf",
  EML          : "message/rfc822"
}

export const UNSUPPORTED_MIME_TYPE = {
  MSG: "application/vnd.ms-outlook"
}

export const DEFAULT_LANGUAGE = {
  name     : "English",
  shortName: "en-GB"
}

export const RTL_LANGUAGES = ["ar", "he", "fa", "ur"]

export const ARRAY = {
  FIRST : 0,
  SECOND: 1
}
export default {
  COOKIE_STORAGE_DATA,
  COGNITO_AUTHENTICATION_MESSAGE,
  HTTP_STATUS_CODE,
  COGNITO_USER_POOL_DATA,
  FORM_INSTANCE_FIELD_TYPES,
  ARRAY,
  WIDGET,
  ERROR,
  APPLICATION_ID      : 10003,
  SAVED_TIMEOUT       : 5000,
  NOTIFICATION_TIMEOUT: 5000,
  KEY_CODE            : {
    BACKSPACE   : 8,
    ARROW_LEFT  : 37,
    ARROW_RIGHT : 39,
    ZERO        : 48,
    NINE        : 57,
    NUM_PAD_ZERO: 96,
    NUM_PAD_NINE: 105
  },
  MESSAGE_STATUS,
  MESSAGE_ITEM_TYPE,
  REPORT_STATUS: {
    NEW   : "new",
    DRAFT : "draft",
    CLOSED: "closed"
  },
  DAY_IN_MILLI_SECONDS                  : 86400000,
  NUMBER_OF_DAYS_TO_DISABLE_REPORT_LOGIN: 30,
  ALLOWABLE_FILE_LIMIT                  : 25,
  FILE_SIZE_LIMIT                       : 524288000,
  DEFAULT_LANGUAGE,
  RTL_LANGUAGES,
  USER_ATTRIBUTE_UPDATE,
  USER_ATTRIBUTE_VERIFICATION,
  USER_ATTRIBUTE
}