/**
 * @file It contains vuex channels module getter methods to access store variables.
 */
export default {
  isLoadingChannels                  : state => state.loadingChannels,
  channels                           : state => state.channels,
  formTemplatesOfChannels            : state => state.formTemplatesOfChannels,
  isLoadingFormTemplatesForChannel   : state => state.loadingFormTemplatesForChannel,
  logoOfChannels                     : state => state.logoOfChannels,
  translationConfigurationsOfChannels: state => state.translationConfigurationsOfChannels,
  isLoadingTranslationConfigurations : state => state.loadingTranslationConfigurations,
  organisationCode                   : state => state.organisationCode,
  isOrganisationCodeVerified         : state => state.organisationCodeVerified,
  isVerifyingOrganisationCode        : state => state.verifyingOrganisationCode,
  isLoadingChannelLogo               : state => state.loadingChannelLogo
}