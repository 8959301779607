/**
 * @file It contains vuex token module mutations to mutate the state variables.
 */
export default {
  setClientName: (state, clientName) => {
    state.clientName = clientName
  },
  setReport: (state, report) => {
    state.report = report
  },
  setReporterUserPoolId: (state, reporterUserPoolId) => {
    state.reporterUserPoolId = reporterUserPoolId
  },
  setReporterLanguage: (state, reporterLanguage) => {
    state.reporterLanguage = reporterLanguage
  },
  setReporterUserPoolClientId: (state, reporterUserPoolClientId) => {
    state.reporterUserPoolClientId = reporterUserPoolClientId
  },
  setSkipOrganisationCodeForWeb: (state, askOrganisationCodeForWeb) => {
    state.askOrganisationCodeForWeb = askOrganisationCodeForWeb
  },
  setTranslationPreferenceId: (state, translationPreferenceId) => {
    state.translationPreferenceId = translationPreferenceId
  },
  setLoggingIn: (state, loggingIn) => {
    state.loggingIn = loggingIn
  },
  setLoggedIn: (state, loggedIn) => {
    state.loggedIn = loggedIn
  },
  setInvalidCredentials: (state, invalidCredentials) => {
    state.invalidCredentials = invalidCredentials
  },
  setExceededAttempts: (state, exceededAttempts) => {
    state.exceededAttempts = exceededAttempts
  },
  setReportNumber: (state, reportNumber) => {
    state.reportNumber = reportNumber
  },
  setSubscribingForNotification: (state, subscribingForNotification) => {
    state.subscribingForNotification = subscribingForNotification
  },
  setSubscribedForNotification: (state, subscribedForNotification) => {
    state.subscribedForNotification = subscribedForNotification
  },
  setVerifyingEmail: (state, verifyingEmail) => {
    state.verifyingEmail = verifyingEmail
  },
  setEmailVerified: (state, emailVerified) => {
    state.emailVerified = emailVerified
  }
}