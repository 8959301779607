/**
 * @file It contains vuex token module getter methods to access store variables.
 */
export default {
  clientName                  : state => state.clientName,
  reporterUserPoolId          : state => state.reporterUserPoolId,
  reporterUserPoolClientId    : state => state.reporterUserPoolClientId,
  askOrganisationCodeForWeb   : state => state.askOrganisationCodeForWeb,
  translationPreferenceId     : state => state.translationPreferenceId,
  reporterLanguage            : state => state.reporterLanguage,
  isLoggedIn                  : state => state.loggedIn,
  isLoggingIn                 : state => state.loggingIn,
  isInvalidCredentials        : state => state.invalidCredentials,
  hasExceededAttempts         : state => state.exceededAttempts,
  report                      : state => state.report,
  reportNumber                : state => state.reportNumber,
  isSubscribedForNotification : state => state.subscribedForNotification,
  isSubscribingForNotification: state => state.subscribingForNotification,
  isEmailVerified             : state => state.emailVerified,
  isVerifyingEmail            : state => state.verifyingEmail
}