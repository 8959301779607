/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { downloadFile } from "@/utils"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}channels`

export default {
  /**
   * This action will load channels.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load channels.
   */
  async loadChannels(context, payload) {
    context.commit("setLoadingChannels", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getChannelsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getChannelsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateChannels", getChannelsResponse.data)
      } else {
        context.commit("setChannels", getChannelsResponse.data)
      }
    } else {
      // something went wrong while getting channels
    }

    context.commit("setLoadingChannels", false)
  },

  /**
   * This action will load formTemplates of a channel.
   * @param {*} context is the store.
   */
  async loadFormTemplates(context, id) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getFormsResponse = await httpClient.get(`/${id}/form-templates`)

    if (getFormsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setFormTemplatesForChannel", {
        id            : id,
        formTemplateId: getFormsResponse.data
      })
    }

    context.commit("setLoadingFormTemplatesForChannel", false)
  },

  /**
   * This action will load forms of a channel.
   * @param {*} context is the store.
   */
  async loadTranslationConfigurations(context, id) {
    context.commit("setLoadingTranslationConfigurations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getTranslationConfigurationsResponse = await httpClient.get(`/${id}/translation-configurations`)

    if (getTranslationConfigurationsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setTranslationConfigurationsForChannel", {
        id,
        translationConfigurationsOfChannels: getTranslationConfigurationsResponse.data
      })
    } else {
      // something went wrong while getting forms
    }

    context.commit("setLoadingTranslationConfigurations", false)
  },

  /**
   * This action will verify organisation code for a channel.
   * @param {*} context is the store.
   * @param {*} payload contains the data to be verified.
   */
  async verifyOrganisationCode(context, payload) {
    context.commit("setOrganisationCodeVerified", undefined)
    context.commit("setVerifyingOrganisationCode", true)

    const baseURL    = `${process.env.VUE_APP_THEMIS_API_BASE_URL}`
    const httpClient = GET_HTTP_CLIENT({
      baseURL,
      auth: {
        username: "public",
        password: payload.organisationCode
      }
    }, context)

    const result = await httpClient.post("/verify-organisation-codes")
    if (result) {
      if (result.data.channel === payload.channelName) {
        context.commit("setOrganisationCodeVerified", true)
        setTimeout(() => {
          context.commit("setOrganisationCodeVerified", undefined)
        }, 5000)
        context.commit("setOrganisationCode", payload.organisationCode)
      } else {
        context.commit("setOrganisationCodeVerified", false)
      }
    } else {
      context.dispatch("notifications/notify", {
        type: "error",
        text: "NOTIFICATION_UNEXPECTED_ERROR"
      }, { root: true })
    }
    context.commit("setVerifyingOrganisationCode", false)
  },

  /**
   * This action gets channel logo
   * @param {*} context is the store.
   * @param {*} id of a channel.
   * @param {*} md5 checksum value to be compared with for integrity.
   * @param {*} name of the channel logo.
   */
  async loadChannelLogo(context, channel) {
    context.commit("setLoadingChannelLogo", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getLogoResponse = await httpClient.get(`/${channel.id}/logo`)

    if (getLogoResponse.status === HTTP_STATUS_CODE.OK) {
      const logo =
        await downloadFile(getLogoResponse.data, channel.logo.md5, channel.logo.name, false)
      context.commit("setLogoForChannel", {
        id: channel.id,
        logo
      })
    }
    context.commit("setLoadingChannelLogo", false)
  },

  /**
   * This action is used to reset channel.
   * @param {*} context is the store.
   */
  async reset(context) {
    context.commit("setLoadingChannels", false)
    context.commit("setLoadingFormTemplatesForChannel", false)
    context.commit("resetFormTemplatesOfChannels")
    context.commit("resetLogoOfChannels")
    context.commit("setLoadingTranslationConfigurations", false)
    context.commit("setLoadingChannelLogo", false)
    context.commit("setOrganisationCode", undefined)
    context.commit("setOrganisationCodeVerified", undefined)
    context.commit("setVerifyingOrganisationCode", undefined)
  }
}