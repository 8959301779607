/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { COGNITO_AUTHENTICATION_MESSAGE, USER_ATTRIBUTE_UPDATE, USER_ATTRIBUTE_VERIFICATION } from "@/constants"
import Cognito from "@/utils/cognito"

export default {
  /**
   * This action will login to the system using credentials.
   * @param {*} context is the store.
   * @param {*} payload contains username and password
   */
  async loginUsingCredentials(context, payload) {
    context.commit("setLoggingIn", true)
    context.commit("setInvalidCredentials", false)
    context.commit("setExceededAttempts", false)

    const authenticateUserResult = await Cognito.authenticateUser(
      context.getters.reporterUserPoolId,
      context.getters.reporterUserPoolClientId,
      payload.username.toString(),
      payload.password,
      payload.newPassword
    )

    if (authenticateUserResult) {
      if (authenticateUserResult.code === "NotAuthorizedException") {
        if (authenticateUserResult.message === COGNITO_AUTHENTICATION_MESSAGE.INVALID_CREDENTIALS){
          context.commit("setInvalidCredentials", true)
        } else if (authenticateUserResult.message === COGNITO_AUTHENTICATION_MESSAGE.EXCEEDED_ATTEMPTS){
          context.commit("setExceededAttempts", true)
        }
      } else {
        const loggedInSession = await Cognito.getSession(
          context.getters.reporterUserPoolId,
          context.getters.reporterUserPoolClientId
        )

        const report = JSON.parse(loggedInSession.getIdToken().decodePayload().report)
        context.commit("setReport", report)
        context.commit("setReportNumber", payload.username)
        context.commit("setReporterLanguage", context.rootGetters["languages/languages"].find(language => language.id === report.languageId))
        context.commit("setLoggedIn", true)
      }
    }
    context.commit("setLoggingIn", false)
  },

  /**
   * This action will subscribe for notification.
   * @param {*} context is the store.
   * @param {*} payload contains email id of user
   */
  async subscribeForNotification(context, payload) {
    context.commit("setSubscribedForNotification", false)
    context.commit("setSubscribingForNotification", true)

    const updateUserAttributesResult = await Cognito.updateUserAttributes(
      context.getters.reporterUserPoolId,
      context.getters.reporterUserPoolClientId,
      payload
    )

    if (updateUserAttributesResult === USER_ATTRIBUTE_UPDATE.SUCCESS) {
      context.commit("setSubscribedForNotification", true)
    }
    context.commit("setSubscribingForNotification", false)
  },

  /**
   * This action will verify email using verification code.
   * @param {*} context is the store.
   * @param {*} payload contains verification code
   */
  async verifyEmail(context, payload) {
    context.commit("setEmailVerified", false)
    context.commit("setVerifyingEmail", true)
    const verifyEmailResult = await Cognito.verifyEmailUsingVerificationCode(
      context.getters.reporterUserPoolId,
      context.getters.reporterUserPoolClientId,
      payload
    )

    if(verifyEmailResult === USER_ATTRIBUTE_VERIFICATION.SUCCESS) {
      context.commit("setEmailVerified", true)
    }
    context.commit("setVerifyingEmail", false)
  },

  /**
   * This action will log user out of the system and clear store.
   * @param {*} context is the store.
   */
  async logout(context) {
    context.dispatch("shared/reset", undefined, { root: true })
    context.dispatch("reports/reset", undefined, { root: true })
    context.dispatch("messages/reset", undefined, { root: true })
    context.dispatch("messageItems/reset", undefined, { root: true })
    context.dispatch("channels/reset", undefined, { root: true })
    context.dispatch("formInstances/reset", undefined, { root: true })
    context.dispatch("formTemplates/reset", undefined, { root: true })
    context.dispatch("survey/reset", undefined, { root: true })

    try {
      await Cognito.logout(
        context.getters.reporterUserPoolId,
        context.getters.reporterUserPoolClientId
      )
    } finally {
      context.dispatch("reset", undefined)
    }
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  async reset(context) {
    context.commit("setLoggingIn", false)
    context.commit("setLoggedIn", false)
    context.commit("setReport", undefined)
    context.commit("setExceededAttempts", undefined)
    context.commit("setInvalidCredentials", undefined)
    context.commit("setReportNumber", undefined)
    context.commit("setReporterLanguage", undefined)
    context.commit("setSubscribingForNotification", undefined)
    context.commit("setSubscribedForNotification", undefined)
    context.commit("setVerifyingEmail", undefined)
    context.commit("setEmailVerified", undefined)
  }
}