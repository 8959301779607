/**
 * @file It contains all the vuex token module state variables.
 */
export default {
  clientName                : undefined,
  reporterUserPoolId        : undefined,
  reporterUserPoolClientId  : undefined,
  reporterLanguage          : undefined,
  loggedIn                  : false,
  invalidCredentials        : undefined,
  loggingIn                 : false,
  exceededAttempts          : undefined,
  report                    : undefined,
  reportNumber              : undefined,
  translationPreferenceId   : undefined,
  askOrganisationCodeForWeb : undefined,
  subscribingForNotification: undefined,
  subscribedForNotification : undefined,
  verifyingEmail            : undefined,
  emailVerified             : undefined
}
